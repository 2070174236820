





















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import SowosPocketInfoViewModel from '@/vue-app/view-models/components/my-investment-dashboard-v2/sowos-pocket-info-view-model';

@Component({ name: 'SowosPocketInfo' })
export default class SowosPocketInfo extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  sowos_pocket_info_model = Vue.observable(new SowosPocketInfoViewModel());

  closeModal() {
    this.synced_is_open = false;
  }
}
