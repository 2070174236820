























































import { Component, PropSync, Vue } from 'vue-property-decorator';
import FundingDetailsKuspitAccountViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard/funding-details-kuspit-account-view-model';

@Component({ name: 'FundingDetailsKuspitAccount' })
export default class FundingDetailsKuspitAccount extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  funding_details_kuspit_view_model = Vue.observable(new FundingDetailsKuspitAccountViewModel());

  async created() {
    await this.funding_details_kuspit_view_model.initialize();
  }
}
