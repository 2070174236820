import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import {
  GetFundingDetailsQuery,
} from '@/modules/my-investment/kuspit/funding-details/application/queries';
import Inject from '@/modules/shared/domain/di/inject';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';

export default class FundingDetailsKuspitAccountViewModel {
  @Inject(TYPES.GET_KUSPIT_FUNDING_DETAILS_QUERY)
  private readonly get_funding_details_query!: GetFundingDetailsQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  private readonly get_customer_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  readonly i18n_namespace = 'components.my-investment-dashboard.fund-kuspit-account';

  kuspit_clabe = '';

  bank_name = '';

  get funding_details() {
    return this.get_funding_details_query.execute();
  }

  get has_kuspit_account() {
    return this.get_customer_has_kuspit_account_query.execute();
  }

  translate = (value: string, count?: number, values?: Record<string, string | number>) => (values ? i18n.tc(`${this.i18n_namespace}.${value}`, count, values) as string : i18n.t(`${this.i18n_namespace}.${value}`) as string);

  initialize = async () => {
    const kuspit_account = await this.has_kuspit_account;
    if (kuspit_account.has_account) {
      const funding_details_kuspit = await this.funding_details;
      this.kuspit_clabe = funding_details_kuspit.kuspit_clabe;
      this.bank_name = funding_details_kuspit.bank;
    }
  }
}
