import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { currencyFormat } from '@/vue-app/utils/currency';
import { v4 } from 'uuid';

// Application
import {
  GetCustomerKuspitPositionDetailsQuery,
  GetCustomerKuspitPositionQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';
import GetRecommendedInvestmentProductQuery
  from '@/modules/flagship/investor-profile/recommended-investment-product/application/queries/get-recommended-investment-product-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';
import {
  FindAvailabilityToHireProductQuery,
} from '@/modules/my-investment/investment-product/application/queries';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { InvestorProfileStateManager }
  from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Router } from '@/modules/shared/domain/router';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class SowosPocketCardViewModel {
  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  private readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  @Inject(TYPES.GET_RECOMMENDED_INVESTMENT_PRODUCT_QUERY)
  private readonly get_recommended_product_query!: GetRecommendedInvestmentProductQuery;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  private readonly investor_profile_state_manager!: InvestorProfileStateManager;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_kuspit_position_details_query!:
    GetCustomerKuspitPositionDetailsQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.FIND_AVAILABILITY_TO_HIRE_PRODUCT_QUERY)
  private readonly find_availability_to_hire_product!: FindAvailabilityToHireProductQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products!: GetInvestmentProductsQuery;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.my-investment-dashboard.sowos-pocket-card';

  readonly asset = 'icon_ahorro.svg';

  is_loading = false;

  balance = '0.00 MXN';

  has_kuspit_account = false;

  kuspit_has_funds = false;

  updated_date = '';

  show_kuspit_funding_details_modal = false;

  show_info = false;

  data_update_failure = '';

  bg_image = {
    backgroundImage: '@/assets/images/investment-dashboard/background_blue_sky.svg',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
  }

  display_contract_savings = false;

  show_contract_savings_modal = false;

  has_account = false;

  account_without_contract = false;

  show_recommended = false;

  contract_number = '';

  investment_provider_id = '';

  show_pocket_is_on_maintenance_dialog = false;

  available_pocket_services = 'available';

  kuspit_portal_url = process.env.VUE_APP_KUSPIT_PORTAL_URL;

  product_unavailable_to_hire = '';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: 'kuspit',
  }

  get has_account_with_funds() {
    return this.has_kuspit_account && this.kuspit_has_funds;
  }

  get has_account_without_funds() {
    return this.has_kuspit_account && !this.kuspit_has_funds;
  }

  get position_details_kuspit() {
    return this.get_customer_kuspit_position_details_query.execute();
  }

  get searchOnBoardingSteps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  setInvestmentProviderId = (
    investment_providers: Array<InvestmentProviderEntity>, service: string,
  ) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === service,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  loadKuspitData = async () => {
    try {
      const kuspit_account = await this.get_has_kuspit_account_query.execute();
      this.has_kuspit_account = kuspit_account.has_account;
      if (kuspit_account.has_account) {
        const kuspit_position_data = await this.get_customer_kuspit_position_query.execute();
        this.kuspit_has_funds = kuspit_position_data.has_funds;
        this.data_update_failure = kuspit_position_data.update_data;
        this.updated_date = (kuspit_position_data.update_date
          ? this.date_formatter.formatDate(kuspit_position_data.update_date, 'DD MMM YYYY') : this.translate('not_loaded_date'));
        this.balance = `${currencyFormat(parseFloat(kuspit_position_data.position))} MXN`;

        if (kuspit_position_data.has_funds) {
          const kuspit_position = await this.position_details_kuspit;
          if (kuspit_position) {
            this.contract_number = kuspit_position.contract_id;
          }
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_has_kuspit_account'));
    }
  }

  goToPocketDashboard = () => {
    if (this.available_pocket_services === 'available') {
      this.router.navigate('sowos-pocket');
    }
  }

  fundAccount = () => {
    this.show_kuspit_funding_details_modal = true;
  }

  showInfo = ($event: Event) => {
    $event.stopPropagation();
    this.show_info = true;
  }

  findAvailabilityToHire = async (investment_provider_id: string) => {
    let available_to_hire = false;
    const investment_products = await this.get_investment_products.execute();
    const investment_product = investment_products.find(
      (product) => product.investment_provider_id === investment_provider_id
      && product.name === 'sowos_pocket',
    );
    if (investment_product) {
      const availability = await this.find_availability_to_hire_product.execute(
        investment_product.id,
      );
      available_to_hire = availability.available_to_hire;
    }
    return available_to_hire;
  }

  start = async () => {
    const available = await this.findAvailabilityToHire(this.investment_provider_id);
    if (!available) {
      this.product_unavailable_to_hire = 'SOWOS Pocket';
    } else if (this.available_pocket_services === 'available') {
      await this.obtainInvestmentSuggestion();
      this.show_contract_savings_modal = true;
    } else {
      this.show_pocket_is_on_maintenance_dialog = true;
    }
  }

  translate = (value: string, count?: number, values?: Record<string, string | number>) => (values ? i18n.tc(`${this.i18n_namespace}.${value}`, count, values) as string : i18n.t(`${this.i18n_namespace}.${value}`) as string);

  setBackgroundImage = () => {
    if (this.kuspit_has_funds) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-unresolved
      const image = require('@/assets/images/investment-dashboard/background_blue_sky.svg');
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      const image_mobile = require('@/assets/images/investment-dashboard/background_blue_sky.png');
      // eslint-disable-next-line no-restricted-globals
      this.bg_image.backgroundImage = (screen.width < 480) ? `url(${image_mobile})` : `url(${image})`;
      this.bg_image.backgroundSize = 'cover';
    }

    if (!this.kuspit_has_funds) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-unresolved
      const image = require('@/assets/images/investment-dashboard/join_pocket.svg');
      this.bg_image.backgroundImage = `url(${image})`;
      this.bg_image.backgroundSize = 'contain';
    }
  }

  async initialize() {
    this.is_loading = true;
    await this.loadKuspitData();
    await this.setBackgroundImage();

    if (!this.has_kuspit_account && !this.kuspit_has_funds) {
      const investment_providers = await this.investmentProviders;
      this.setInvestmentProviderId(investment_providers, 'kuspit');

      const steps = await this.searchOnBoardingSteps;

      const search_step_status = steps.find(
        (step) => step.current_step === 'on_boarding_kuspit_status',
      );

      if (search_step_status) {
        if (search_step_status.payload.status === 'account_without_contract') {
          this.account_without_contract = true;
        } else if (search_step_status.payload.status === 'wrong_credentials'
          || search_step_status.payload.status === 'has_account') {
          this.has_account = true;
        } else {
          this.display_contract_savings = search_step_status.payload.status !== 'has_account';
        }
      } else {
        this.display_contract_savings = true;
      }
    }

    this.is_loading = false;
  }

  getRecommendedProduct = async (investor_profile_id: string) => {
    try {
      const { investment_product } = await this.get_recommended_product_query
        .execute(investor_profile_id);
      if (investment_product) {
        this.show_recommended = (investment_product.name === 'sowos_poket');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_recommended_product'));
    }
  }

  obtainInvestmentSuggestion = async () => {
    try {
      const { id, must_be_shown } = await this.get_investment_suggestion_query.execute();
      if (must_be_shown) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = id;
        await this.createInvestmentSuggestionAction();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investment_suggestion'));
    }
  }

  createInvestmentSuggestionAction = async () => {
    try {
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_investment_suggestion_action'));
    }
  }
}
